<template>
    <section class="form-preview">
        <!-- Form Preview -->
        <!-- <form-builder v-if="data" :data="data" @submit="saveForm"/> -->
        <LinkExpired v-if="hasError"/>
        <template v-else>
            <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-100 opacity-75 flex flex-col items-center justify-center">
                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 class="text-center text-primary text-xl font-semibold">Loading...</h2>
                <p class="w-1/3 text-center text-primary">This may take a few seconds, please don't close this page.</p>
            </div>
        </template>
    </section>
</template>

<script>
import VueCookies from "vue-cookies";

import { FormsPreviewServ } from "../services";

import LinkExpired from "@shared/components/Error-Message";

export default {
    name: "FormPreview",
    data() {
        return { hasError: false };
    },
    computed: {
        getToken() {
            return this.$route.params.token;
        },
        getFormType() {
            return this.$route.params.type;
        },
    },
    async mounted() {
        await this.validate();
    },
    methods: {
        async validate() {
            try {
                const data = await FormsPreviewServ.validateToken({ token: this.getToken });
                VueCookies.set("additional-form-token", data.access_token);
                VueCookies.set("additional-form-refresh-token", data.access_token);
                this.$router.push(`/additional-form/type/${this.getFormType}`);
            } catch (error) {
                this.hasError = true;
                console.log(error, "[+] failed to validate");
            }
            // return true;
        },
    },
    components: {
        LinkExpired
    }
};
</script>

<style lang="scss" scoped>
.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
