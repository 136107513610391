<template>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-100 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg mobileExpiry">
                    <div class="bg-white rounded-lg">
                        <div class="p-8 text-center">
                            <svg aria-hidden="true" class="mx-auto mb-4 text-red-400 w-14 h-14" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h3 class="mb-1 text-lg font-normal text-primary">
                                {{ message }}
                            </h3>
                            <span class="text-gray-600 text-sm">Please Contact your administrator.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErrorMessage",
    props: {
        message: {
            type: String,
            default: "The link you followed has expired or invalid!",
        },
    },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 300px) and (max-width: 500px) {
    .mobileExpiry {
        bottom: 400px;
    }
}

</style>
